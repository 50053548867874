import React, { useState } from 'react';
import Stepper from './testzone/Stepper'
import HideGuess from './testzone/HideGuess'
import '../styles/Test.css';

function Test() {

    return (
        <div className='test-container'>
            <Stepper />

            <HideGuess />
        </div>
    );
}
export default Test