import { useState } from 'react';

import '../styles/Portfolio.css';

function Hobby( {SelectedSkill}) {

  const [hobbys] = useState(
    [{position: "React Developer", project: "TwixTheCorgi.com", country: "France",
    startDate: "15/02/2024", endDate: "Current", 
    description: ["Deployment of website through GoDaddy",
    "Rebuild website to match new use case"], hobbyId: 2},
    
    {position: "Full-Stack Developer", project: "TwixTheCorgi.com", country: "France",
    startDate: "01/08/2023", endDate: "01/11/2023", 
    description: ["Deployment of website through Microsoft Azure",
    "Building back-end and designing front-end from scratch",
    "Manage project in DevOps and Github",
    "Create and handle WebAPI and server structure"], hobbyId: 1}])
  
  return (
    <>
      {SelectedSkill ?
        hobbys.map( (hobby, i) => SelectedSkill.hobby.includes(hobby.hobbyId) &&
        <article className='show-box' key={i}>
          <h2>{hobby.project}</h2>
            <h3 className='selected-header'>{hobby.position}</h3>
            <p className='date'>{hobby.startDate} - {hobby.endDate === "Current" ? <b>{hobby.endDate}</b> : hobby.endDate}</p>
            <p className='type box hobby'>Solo Project</p>
           <p className='country box hobby'>{hobby.country}</p>

          <ul className="description">
            {hobby.description.map( (desc, i) =>
              <li key={i} >{desc}</li>)}
          </ul>
        </article>)
      :
      hobbys.map( (hobby, i) => 
        <article className='show-box' key={i}>
          <h2>{hobby.project}</h2>
            <h3 className='hobby'>{hobby.position}</h3>
            <p className='date'>{hobby.startDate} - {hobby.endDate === "Current" ? <b>{hobby.endDate}</b> : hobby.endDate}</p>
            <p className='type box hobby'>Solo Project</p>
            <p className='country box hobby'>{hobby.country}</p>

          <ul className="description">
            {hobby.description.map( (desc, i) =>
              <li key={i} >{desc}</li>)}
          </ul>
        </article>)}
    </>
  );
}

export default Hobby;