import '../styles/Twix.css';
import Twix1 from './Twix1'
import Twix2 from './Twix2'

function Twix() {
  return (
    <div className='twix-container'>
      <Twix1 />
      <Twix2 />
    </div>
  );
}

export default Twix;