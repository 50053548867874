import { useState } from 'react';

import Jobs from './Jobs';
import Education from './Education';
import Hobbys from './Hobby';
import Footer from './Footer';
import '../styles/Portfolio.css';

function Portfolio() {

  //Job meanings: 0-not from any work, 1-first work position, 2-second, etc. etc.
  //Edu meanings: same as job / Hobby meanings: same as job
  //Skill meanings: 0-back-end skills, 1-front-end skills, 2-soft skills

  // fixa design på listorna

  const [skills] = useState([
    {name: "C#", type: 0, job: [1,2], edu: [1], hobby: [1], id: 'b01'},
    {name: "MVC", type: 0, job: [1], edu: [1], hobby: [0], id: 'b02'},
    {name: "Web API", type: 0, job: [2], edu: [1], hobby: [1], id: 'b03'},
    {name: "ASP Net Core", type: 0, job: [1], edu: [1], hobby: [0], id: 'b04'},
    {name: "SQL", type: 0, job: [1,2], edu: [1], hobby: [1], id: 'b05'},
    {name: "JSON", type: 0, job: [1,2], edu: [2], hobby: [1,2], id: 'b06'},
    {name: "Linq", type: 0, job: [1,2], edu: [0], hobby: [1], id: 'b07'},

    {name: "HTML", type: 1, job: [2], edu: [1], hobby: [1,2], id: 'f01'},
    {name: "CSS", type: 1, job: [1,2], edu: [1], hobby: [1,2], id: 'f02'},
    {name: "JavaScript", type: 1, job: [1,2], edu: [1], hobby: [1,2], id: 'f03'},
    {name: "Jquery", type: 1, job: [2], edu: [2], hobby: [0], id: 'f04'},
    {name: "React", type: 1, job: [2], edu: [1], hobby: [1,2], id: 'f05'},
    {name: "Vue", type: 1, job: [0], edu: [1], hobby: [0], id: 'f06'},
    {name: "Razor", type: 1, job: [1], edu: [0], hobby: [0], id: 'f07'},

    {name: "Microsoft Office", type: 2, job: [0], edu: [2], hobby: [0], id: 's01'},
    {name: "Microsoft Azure", type: 2, job: [0], edu: [2], hobby: [1], id: 's02'},
    {name: "Git", type: 2, job: [0], edu: [1], hobby: [1], id: 's03'},
    {name: "Agile", type: 2, job: [1,2], edu: [1], hobby: [0], id: 's04'},
    {name: "DevOps", type: 2, job: [1,2], edu: [1], hobby: [1], id: 's05'},
    {name: "Visual Studio", type: 2, job: [1,2], edu: [1], hobby: [1,2], id: 's06'}
  ])

  const [selectedSkill, setSelectedSkill] = useState("")
  
  return (
    <div className='about-container'>

      <div className='skills'>

        <section className='sh-container'>
          <div className='display-box title-box'>
            <h4 className='top-text title'>Title</h4>
            <h2 className='main-text skills-head'>.Net Developer</h2>
            <h4 className='top-text title'>Technical skills</h4>
          </div>

          <div className='display-box divider'>
            <p className='top-text '></p>
            <p className='main-text skills-head'></p>
            <p className='top-text '></p>
          </div>

          <div className='display-box experience'>
            <h4 className='top-text'>Years of</h4>
            <h2 className='main-text'>2</h2>
            <h4 className='bottom-text'>Experience</h4>
          </div>
        </section>

        <section className='sl-container'>
          <article>
            <h3>Back-End</h3>
            <ul>
              {skills.map( (skill) => skill.type === 0 &&
                <li className={skill === selectedSkill ? "selected" : ""} key={skill.id} onClick={() => setSelectedSkill(skill)}>{skill.name}</li>)}
            </ul>
          </article>

          <article>
            <h3>Front-End</h3>
            <ul>
              {skills.map( (skill) => skill.type === 1 &&
                <li className={skill === selectedSkill ? "selected" : ""} key={skill.id} onClick={() => setSelectedSkill(skill)}>{skill.name}</li>)}
            </ul>
          </article>

          <article>
            <h3>Soft</h3>
            <ul>
              {skills.map( (skill) => skill.type === 2 &&
                <li className={skill === selectedSkill ? "selected" : ""} key={skill.id} onClick={() => setSelectedSkill(skill)}>{skill.name}</li>)}
            </ul>
          </article>
        </section>

        <div className='select-display'>
          {selectedSkill && <button className='clear-btn' onClick={() => setSelectedSkill("")} >X</button>}
          <h2>{selectedSkill.name}</h2>
        </div>
        
        <section className='show-container'>
          <Jobs SelectedSkill={selectedSkill}/>
          <Education SelectedSkill={selectedSkill}/>
          <Hobbys SelectedSkill={selectedSkill}/>
        </section>
      </div>
    
      <Footer />
      
    </div>
  );
}

export default Portfolio;