import { Route } from 'react-router';
import Layout from './components/Layout';
import Intro from './components/Intro';
import Twix from './components/Twix';
import Test from './components/Test';
import Portfolio from './components/Portfolio';
import Language from './components/Language';

import './custom.css'

function App() {

  return (
    <Layout>
      <Route path='/Twix' component={Twix} />
      <Route path='/Portfolio' component={Portfolio} />
      <Route path='/Language' component={Language} />
      <Route path='/Testzone' component={Test} />
      <Route exact path='/' component={Intro} />
    </Layout>
  );
}
export default App