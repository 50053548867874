import { useState } from 'react';

import '../styles/Portfolio.css';
import housePin from '../images/house-pin.svg'

function Jobs( {SelectedSkill}) {

  //Job meanings: 0-learnt outside work, 1-first work position, 2-second, etc. etc.


  const [jobs] = useState(
    [{position: "Garde d'enfants à domicile", company: "", country: "France",
    startDate: "27/11/2023", endDate: "31/3/2024", 
    description: ["Driving children to their extracurricular activities.",
    "Wash / Dry / Iron / Fold clothes.",
    "Maintain living spaces",
    "Prepare meals"], jobId: 3},
    
    {position: "Relocated to France", company: "", country: "",
    startDate: "", endDate: "", 
    description: [], jobId: 999},
    
    {position: "Full-Stack Developer", company: "FSD AB", country: "Sweden",
    startDate: "07/03/2022", endDate: "30/06/2022", 
    description: ["Rebuilt a razor front-end as a React web app.",
    "Changed back-end from MVC to an API.",
    "Provided solutions to customer requests which were outside the scope of the app."], jobId: 2},
    
    {position: "Full-Stack Developer", company: "FSD AB", country: "Sweden",
    startDate: "27/08/2021", endDate: "17/12/2021", 
    description: ["Expanded and maintained newly implemented features.",
    "Updated and changed old non-functioning code as needed."], jobId: 1}])
  
  return (
    <>
      {SelectedSkill ?
        jobs.map( (job, i) => SelectedSkill.job.includes(job.jobId) &&
        <article className='show-box' key={i}>
          <h2>{job.company}</h2>
            <h3 className='selected-header'>{job.position}</h3>
            <p className='date'>{job.startDate} - {job.endDate === "Current" ? <b>{job.endDate}</b> : job.endDate}</p>
            <p className='type box job'>Work</p>
            <p className='country box job'>{job.country}</p>

          <ul className="description">
            {job.description.map( (desc, i) =>
              <li key={i} >{desc}</li>)}
          </ul>
        </article>)
      :
      jobs.map( (job, i) => 
        <article className='show-box' key={i}>
          <h2>{job.company}</h2>
          <h3 className='job'>{job.position}</h3>
          
          {job.jobId !== 999 ? <>
            <p className='date'>{job.startDate} - {job.endDate === "Current" ? <b>{job.endDate}</b> : job.endDate}</p>
            <p className='type box job'>Work</p>
            <p className='country box job'>{job.country}</p>
          </> : 
            <img src={housePin} alt='' className='relocation-marker'/>
          }

          <ul className="description">
            {job.description.map( (desc, i) =>
              <li key={i} >{desc}</li>)}
          </ul>
        </article>)}
    </>
  );
}

export default Jobs;