import Footer from './Footer';
import '../styles/Language.css';
import swedish from '../images/swedish.svg'
import french from '../images/french.svg'
import english from '../images/english.svg'

function Language() {
  
  return (
    <div className='language-container'>

      <div id='rotation-box' className='rotation-box'>
        <div className='desc-w'>
          <h3 className='native'>Native</h3>
          <h3 className='advanced'>Advanced</h3>
        </div>

        {/* <div className='desc-h'>
          <h3 className='native'>Native</h3>
          <h3 className='basic'>Basic user</h3>
        </div> */}

        <div className='diagram-display'>
          
          <img src={swedish} alt='' className='swedish' />
          <img src={french} alt='' className='french' />
          <img src={english} alt='' className='english' />
          <div className="pie animate no-round basic-pie" style={{'--p': 25, '--c': 'firebrick'}}></div>
          <div className="pie animate no-round intermediate-pie" style={{'--p': 25, '--c': 'gold'}}></div>
          <div className="pie animate no-round advanced-pie" style={{'--p': 25, '--c': 'green'}}></div>
          <div className="pie animate no-round native-pie" style={{'--p': 25, '--c': 'blue'}}></div>

        </div>

        <div className='desc-w'>
          <h3 className='basic'>Basic user</h3>
          <h3 className='intermediate'>Intermediate</h3>
        </div>
        
        {/* <div className='desc-h'>
          <h3 className='advanced'>Advanced</h3>
          <h3 className='intermediate'>Intermediate</h3>
        </div> */}
      
      </div>

      <Footer />
      
    </div>
  );
}

export default Language;