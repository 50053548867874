import { useState } from 'react';
import '../../styles/Test.css';

function Stepper() {

    const [selectedStep, setSelectedStep] = useState(0)
    const [currentStep, setCurrentStep] = useState(0)
    const [maxStep, setMaxStep] = useState(5)

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    async function moveElevator(toStep){
        setSelectedStep(toStep)
        var step = currentStep

        if(toStep > step){
            do{
                ++step
                await delay(250)
                setCurrentStep(step)
            }
            while(toStep !== step)
        }

        else if(toStep < step){
            do{
                --step
                await delay(250)
                setCurrentStep(step)
            }
            while(toStep !== step)
        }
    }

    function checkNumber(number){
        if(number <= 1) setMaxStep(1)
        else if(number >= 1000) setMaxStep(1000)
        else setMaxStep(parseInt(number))
    }

    return (
        <article className='step-container'>
            <h3>Stepper</h3>
            <pre>Takes 2 steps per second</pre>
            <pre>Number of steps available:</pre> 
            <input defaultValue={maxStep} type="number" onChange={(e) => checkNumber(e.target.value)}/>
            <pre>Can not go under 1 or above 1000</pre> 

            <div className='center-this-thing'>
                <label htmlFor="stepper">0</label>
                <input id='stepper' type="range" readOnly value={currentStep} min={0} max={maxStep} />
                <label htmlFor="stepper">{maxStep}</label>
            </div>
            
            <p>On step: {currentStep}</p>
            <p>Move to step</p>
            
            <div className='btn-holder'>
                <button disabled={currentStep !== selectedStep} onClick={() => moveElevator(0)}>0</button>
                {Array.from(Array(maxStep)).map((step, i) => 
                    <button key={i} disabled={currentStep !== selectedStep} onClick={() => moveElevator(i+1)}>{i+1}</button>
                )}
            </div>
            
        </article>
    );
}
export default Stepper