import '../styles/Home.css';

import Footer from './Footer';
import pointer from '../images/cv-pointer.png'

function Intro() {

	var listOfThings = [{name: 'React', bgcolor: '#4abfd8', textcolor: 'black'},
		{name: '.Net', bgcolor: '#5c21e0', textcolor: 'white'},
		{name: 'Vue', bgcolor: '#3fb27f', textcolor: 'black'},
		{name: 'SQL', bgcolor: '#4328d6', textcolor: 'white'},
		
		{name: 'React', bgcolor: '#4abfd8', textcolor: 'black'},
		{name: '.Net', bgcolor: '#5c21e0', textcolor: 'white'},
		{name: 'Vue', bgcolor: '#3fb27f', textcolor: 'black'},
		{name: 'SQL', bgcolor: '#4328d6', textcolor: 'white'},
	]

  	return (
		<div className="home-display">
			<section className="wt-box">

				<h1 className="welcome-text slide-1">Hello</h1>
				<h1 className="welcome-text slide-2">My name is Jesper</h1>

				<p className="intro-text">I am a junior <a href="https://www.canva.com/design/DAEwlajoLk4/S9u-l0wUSnRRjA9Y412jJg/edit?utm_content=DAEwlajoLk4&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton" target="_blank" rel="noreferrer" className="box">.Net developer</a></p>
				<p className="intro-text">Welcome to my page!</p>

				<img className="cv-pointer" src={pointer} alt='' />

			</section>

			<section className='text-rotator'>
				{listOfThings.map((thing, i) => 
					<p key={i} 
					style={{"--order": i, "--bg": thing.bgcolor, "--text": thing.textcolor, 
						"--offset": Math.floor(Math.random() * 200)-100+"px"}}
					>{thing.name}</p>
				)}

				<div className='hider' />
				<div className='hider' />

			</section>
    
			<Footer />
		</div>
  	);
}

export default Intro;