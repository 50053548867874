import { useState } from 'react';

import '../styles/Portfolio.css';

function Education( {SelectedSkill}) {

  const [education] = useState(
    [{position: ".Net Developer", school: "Newton", country: "Sweden",
    startDate: "19/08/2020", endDate: "17/06/2022", 
    description: ["Object Oriented Programming", 
    "Agile system development", 
    "Database technology"], eduId: 1}])
  
  return (
    <>
      {SelectedSkill ?
        education.map( (edu, i) => SelectedSkill.edu.includes(edu.eduId) &&
        <article className='show-box' key={i}>
          <h2>{edu.school}</h2>
            <h3 className='selected-header'>{edu.position}</h3>
            <p className='date'>{edu.startDate} - {edu.endDate === "Current" ? <b>{edu.endDate}</b> : edu.endDate}</p>
            <p className='type box edu'>Education</p>
           <p className='country box edu'>{edu.country}</p>

          <ul className="description">
            {edu.description.map( (desc, i) =>
              <li key={i} >{desc}</li>)}
          </ul>
        </article>)
      :
      education.map( (edu, i) => 
        <article className='show-box' key={i}>
          <h2>{edu.school}</h2>
            <h3 className='edu'>{edu.position}</h3>
            <p className='date'>{edu.startDate} - {edu.endDate === "Current" ? <b>{edu.endDate}</b> : edu.endDate}</p>
            <p className='type box edu'>Education</p>
            <p className='country box edu'>{edu.country}</p>

          <ul className="description">
            {edu.description.map( (desc, i) =>
              <li key={i} >{desc}</li>)}
          </ul>
        </article>)}
    </>
  );
}

export default Education;