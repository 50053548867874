import React, { useState } from 'react';
import '../../styles/Test.css';

function HideGuess() {
    const [hiding, setHiding] = useState(0)
    const [guess, setGuess] = useState(0)
    const [gamesWon, setWon] = useState(0)
    const [gamesLost, setLost] = useState(0)
    const [maxSpots, setMaxSpots] = useState(3)
    const [timer, setTimer] = useState(3)
    const [warning, setWarning] = useState("")
    const [cheated, setCheated] = useState(0)
    const [invert, setInvert] = useState(false)

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    async function hide(spot, inverted = invert){
        if(!inverted) setHiding(spot)
        
        var time = 3
        do{
            --time
            await delay(1000)
            setTimer(time)
        }
        while(time > 0)

        var pcNumber = Math.floor(Math.random() * maxSpots) + 1
        if(!inverted) setGuess(pcNumber) 
        else setHiding(pcNumber)
    }

    function reset(correct){
        if(!invert){
            if(!correct && guess !== hiding) setWon(gamesWon+1)
            else if(correct && guess === hiding) setLost(gamesLost+1)
            else {setWarning("No cheating!"); setCheated(cheated+1); return}
        }
        else if(invert){
            if(!correct && guess !== hiding) setLost(gamesLost+1)
            else if(correct && guess === hiding) setWon(gamesWon+1)
            else {setWarning("No cheating!"); setCheated(cheated+1); return}
        }

        setTimer(3)
        setGuess(0)
        setHiding(0)
        setInvert(false)
        setWarning("")
    }

    function checkNumber(number){
        if(number <= 2) setMaxSpots(2)
        else if(number >= 50) setMaxSpots(50)
        else setMaxSpots(parseInt(number))
    }

    return (
        <article className='hide-container'>
            {!invert ? <h3>Hide from!</h3> : <h3>Guess!</h3>}
            {!invert ?
                <pre>Currently selecting a hiding spot <br />
                3 seconds after selecting a spot the computer will guess <br />
                If the computer guesses the wrong spot you win <br />
                <u onClick={() => {setInvert(true); hide(0, true)}}>Let the computer hide</u></pre>
                :
                <pre>Currently letting the computer hide <br />
                The computer gets 3 seconds to hide before you can guess a spot <br />
                Successfully guessing the correct spot results in a win <br />
                <u onClick={() => setInvert(false)}>Select a hiding spot</u></pre>
            }
            <pre>Number of spots available:</pre> 
            <input defaultValue={maxSpots} type="number" onChange={(e) => checkNumber(e.target.value)}/>
            <pre>Can not go under 2 or above 50</pre> 

            <p>Timer: {timer}s{(invert && timer === 0) && ", Guess!"}</p>

            <div className='btn-holder'>
                {Array.from(Array(maxSpots)).map((spot, i) => <div className='spot-btn' key={i}>
                    {!invert ? 
                        <button key={i} disabled={hiding !== 0} onClick={() => hide(i+1)}>{i+1}</button>
                        :
                        <button key={i} disabled={hiding === 0 || guess !== 0} onClick={() => setGuess(i+1)}>{i+1}</button>
                    }
                    {(!invert || (invert && guess !== 0)) && hiding === i+1 && <div className='coin' />}
                </div>)}
            </div>

            {guess !== 0 && <>
                <p>The guess was {guess}, is it correct? {warning !== "" && warning}{warning !== "" && cheated > 1 && "x"+cheated}</p>
                <button onClick={() => reset(true)}>Yes</button>
                <button onClick={() => reset(false)}>No</button>
            </>}

            {gamesWon > 0 && <p>You have won {gamesWon} game(s)</p>}
            {gamesLost > 0 && <p>You have lost {gamesLost} game(s)</p>}

        </article>
    );
}
export default HideGuess