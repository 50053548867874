import phone from '../images/phone.svg'
import email from '../images/email.svg'
import linkedin from '../images/linkedin.svg'
import '../styles/Footer.css';

function Footer() {

  	return (
    	<footer>
            <div className='link phone'>
                <a className='phone' href="tel: +33767069910" ><img src={phone} alt='' />(+33) 076 706 99 10</a>
            </div>
            
            <div className='link email'>
                <a className='email' href="mailto: JesperLukas.Ljungdahl@gmail.com" ><img src={email} alt='' /> JesperLukas.Ljungdahl@gmail.com</a>
            </div>
            
            <div className='link linkedin'>
                <a className='linkedin' href="https://www.linkedin.com/in/jesper-ljungdahl-002a1b1b5/" target="_blank" rel="noreferrer" ><img src={linkedin} alt='' /> Jesper Ljungdahl</ a>
            </div>
            
    	</footer>
  	);
}

export default Footer;