import React, { useState } from 'react';
import { Container, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/NavMenu.css';

function NavMenu()  {
  const [collapsed, setCollased] = useState(true)

  return (
    // <header>
    //   <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" light>
    //     <Container>
    //       <NavbarBrand tag={Link} to="/">Home</NavbarBrand>
    //       <NavbarToggler onClick={() => setCollased(!collapsed)} className="mr-2" />
    //       <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
    //         <ul className="navbar-nav flex-grow">
    //         <NavItem>
    //             <NavLink tag={Link} onClick={() => setCollased(true)} className="text-dark large" to="/Portfolio">Portfolio</NavLink>
    //           </NavItem>
    //           <NavItem>
    //             <NavLink tag={Link} onClick={() => setCollased(true)} className="text-dark large" to="/Language">Language</NavLink>
    //           </NavItem>
    //           <NavItem>
    //             <NavLink tag={Link} onClick={() => setCollased(true)} className="text-dark" to="/Testzone">Test</NavLink>
    //           </NavItem>
    //         </ul>
    //       </Collapse>
    //     </Container>
    //   </Navbar>
    // </header>
    <header className='head-floater'>
      <Navbar className='navbar-floater'>
        <Container className='navbar-container'>
          <NavbarToggler onClick={() => setCollased(!collapsed)} className="mr-2" />
          <ul className="navbar-nav">
            <NavItem style={{"--order": 1}} className={collapsed ? "hide": "hide move-in"}>
              <NavLink tag={Link} onClick={() => setCollased(true)} 
              className="text-dark large" to="/">Home</NavLink>
            </NavItem>
            <NavItem style={{"--order": 2}} className={collapsed ? "hide": "hide move-in"}>
              <NavLink tag={Link} onClick={() => setCollased(true)} 
              className="text-dark large" to="/Portfolio">Portfolio</NavLink>
            </NavItem>
            <NavItem style={{"--order": 3}} className={collapsed ? "hide": "hide move-in"}>
              <NavLink tag={Link} onClick={() => setCollased(true)} 
              className="text-dark large" to="/Language">Language</NavLink>
            </NavItem>
            <NavItem style={{"--order": 4}} className={collapsed ? "hide": "hide move-in"}>
              <NavLink tag={Link} onClick={() => setCollased(true)} 
              className="text-dark large" to="/Testzone">Test Zone</NavLink>
            </NavItem>
          </ul>
        </Container>
      </Navbar>
    </header>
  );
}

export default NavMenu